// custom styles for the site


// homepage styles
.home {
    .page-heading {
        .container {
            max-width: 1600px;
        }
    }

    .logos {
        padding: 25px 0px 40px;
        display: flex;
        flex-wrap: wrap;

        @media (max-width: $medium-screen) {
            padding: 15px 0px;
        }
        .col {
            display: flex;
            justify-content: center;
            align-items: flex-start;
            flex-direction: column;
            flex: 1 0 auto;
        }
        img {
            @media (max-width: #{ $medium-screen - 1}) {
                align-self: center !important;
            }
        }
    }
}

.provider-row {
    .provider-container {
        margin-bottom: 0.5em;
        box-shadow: $box-shadow-large;
        border-radius: 6px;
        margin-right: 0.75em;
        .provider-info {
            text-align: center;
            & > div {
                border-radius: 6px;
                background-color: white;
                max-width: 220px;
                display: flex;
                flex-direction: column;
                margin: 0 auto;
                height: 100%;
                & > div:first-of-type {
                    max-height: 320px;
                    overflow: hidden;
                    padding: 0;
                    img {
                        width: 100%;
                        height: auto;
                        border-radius: 6px 6px 0 0;
                        display: block;
                    }
                }
                & > div:last-of-type {
                    padding: 1em;
                    padding-bottom: 0.25em;
                    padding-left: 1.5em;
                    padding-right: 1.5em;
                    display: flex;
                    flex-direction: column;
                    flex: auto;
                    h5 {
                        font-weight: 700;
                        font-size: 1.25em;
                        line-height: 1.2;
                        margin-bottom: auto;
                    }
                    a {
                        font-weight: 700;
                    }
                }
            }
        }
        @media (min-width: 500px) {
            display: inline-grid;
        }
        //@media (min-width: 992px) {
        //    padding-right: 0.5em;
        //}
        @media all and (-ms-high-contrast: none), (-ms-high-contrast: active) {
            display: inline-block!important;
        }
    }
    @media (max-width: 750px) {
        justify-content: center;
    }
}