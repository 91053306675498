// Simple grid based on Materialize
$num-cols: 12;
$gutter-width: 1rem;
$container-max: 1280px;

.row {
  margin-left: auto;
  margin-right: auto;

  // Clear floating children
  &:after {
    content: "";
    display: table;
    clear: both;
  }

  .col {
    float: left;
    box-sizing: border-box;
    min-height: 1px;

    &[class*="push-"],
    &[class*="pull-"] {
      position: relative;
    }

    $i: 1;
    @while $i <= $num-cols {
      $perc: unquote((100 / ($num-cols / $i)) + "%");
      &.s#{$i} {
        width: $perc;
        //margin-left: auto;
        left: auto;
        right: auto;
      }
      $i: $i + 1;
    }

    $i: 1;
    @while $i <= $num-cols {
      $perc: unquote((100 / ($num-cols / $i)) + "%");
      &.offset-s#{$i} {
        //margin-left: $perc;
      }
      &.pull-s#{$i} {
        right: $perc;
      }
      &.push-s#{$i} {
        left: $perc;
      }
      $i: $i + 1;
    }

    @media (min-width: $medium-screen) {

      $i: 1;
      @while $i <= $num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");
        &.m#{$i} {
          width: $perc;
          //margin-left: auto;
          left: auto;
          right: auto;
        }
        $i: $i + 1
      }

      $i: 1;
      @while $i <= $num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");
        &.offset-m#{$i} {
          margin-left: $perc;
        }
        &.pull-m#{$i} {
          right: $perc;
        }
        &.push-m#{$i} {
          left: $perc;
        }
        $i: $i + 1;
      }
    }

    @media (min-width: $large-screen) {

      $i: 1;
      @while $i <= $num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");
        &.l#{$i} {
          width: $perc;
          //margin-left: auto;
          left: auto;
          right: auto;
        }
        $i: $i + 1;
      }

      $i: 1;
      @while $i <= $num-cols {
        $perc: unquote((100 / ($num-cols / $i)) + "%");
        &.offset-l#{$i} {
          margin-left: $perc;
        }
        &.pull-l#{$i} {
          right: $perc;
        }
        &.push-l#{$i} {
          left: $perc;
        }
        $i: $i + 1;
      }
    }
  }
}

// flex row
.flex-wrap {
	display: flex;
	flex-wrap: wrap;

  	.col {
		display: flex;
		flex-wrap: wrap;
		padding-left: #{ $gutter-width / 2 };
		padding-right: #{ $gutter-width / 2 };
		padding-bottom: $gutter-width;
  	}
  
}

// percentage column sizes
@media (min-width: $medium-screen) {
  .p20 {
    width: 20%;
  }
  
  .p40 {
    width: 40%;
  }
  
  .p60 {
    width: 60%;
  }
  
  .p80 {
    width: 80%;
  }
}

.container {
    margin: 0 auto;
    max-width: $container-max;
    
	@media (max-width: #{ $container-max + 16 }) {
		padding-left: 16px;
		padding-right: 16px;
	}
}

// list columns

$i: 1;
@while $i <= 4 {
	
	@media (min-width: $medium-screen) {
		.text-col-#{$i} {
			column-count: $i;
		}		
	}
	
	$i: $i + 1;
}